@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

#buttonBox{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#buttonBox > #ctaButton{
  height: 40px;
  width: 200px;
  font-size: 18px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #00ABFF;
  border: none; 
  text-align: center;
  text-decoration: none;
  cursor: pointer; 
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.336);
  font-family: "Open Sans", sans-serif;
}

/* > 720 */
@media (min-width: 720px){
  #buttonBox > #ctaButton{
    height: 49px;
    width: 250px;
    font-size: 25px;
    border-radius: 6px;
  }
}
/* > 720 */

/* > 1410 */
@media (min-width: 1410px){
  #buttonBox > #ctaButton{
    height: 65px;
    width: 300px;
    font-size: 30px;
    border-radius: 6px;
  }
}
/* > 1410 */

/* > 1410  && < 800*/
@media screen and (min-width: 1410px) and (max-height: 820px) {
  #buttonBox > #ctaButton{
    height: 66px;
    width: 334px;
    font-size: 30px;
    border-radius: 10px;
  }
}
/* > 1410  && < 800*/


@media screen and (min-width: 1300px) and (max-height: 760px) {
  #buttonBox > #ctaButton{
    height: 60px;
    width: 280px;
    font-size: 28px;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.336);
  }
}
