#coverageBox{
  background-color: #000000;
  min-width: 320px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  overflow-x: hidden;
}



#coverageBox > #coverageMain{
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  gap: 6vh;
}

#coverageBox > #coverageMain > img{
  width: 100%;
  max-width: 1150px;
}

.textCoverage{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

#textCoverage1 p{
  font-size: 35px;
}

#textCoverage2 p{
  font-size: 15px;
}

/* @media(min-width: 1300px ){

  .textCoverage > p{
    text-align: left;
    font-size: 60px;
  }

} */
/* >= 1000PX */ 


/* > 720 */
@media (min-width: 720px){

  #coverageBox{
    min-height: 40vh;
    padding: 5vh 0;
    padding-top: 10vh;
  }
  
  #coverageBox > #coverageMain{
    gap: 6vh;
  }

  #textCoverage1 p{
    font-size: 55px;
  }
  
  #textCoverage2 p{
    font-size: 20px;
  }
}
/* > 720 */

@media(min-width: 1300px ){

  #coverageBox{
    min-height: 100vh;
    padding: 10vh;

  }
  
  #coverageBox > #coverageMain{
    gap: 6vh;
  }

  #textCoverage1 p{
    font-size: 55px;
  }
  
  #textCoverage2 p{
    font-size: 20px;
  }

  #coverageBox > #coverageMain > img{
    width: 65%;
  }

}
@media screen and (min-width: 400px) and (max-height: 900px) {
  #coverageBox{
    background-color: #000000;
    min-width: 320px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    padding-bottom: 5vh;
  }

}

@media screen and (min-width: 1200px) and (max-height: 760px) {

  #coverageBox{
    min-height: 100vh;
    padding: 11vh;
  }
  
  #coverageBox > #coverageMain{
    gap: 5vh;
  }

  #textCoverage1 p{
    font-size: 45px;
  }
  
  #textCoverage2 p{
    font-size: 18px;
  }

  #coverageBox > #coverageMain > img{
    width: 64%;
    max-width: 1130px;
  }
}

@media (min-width: 1921px) {
  #coverageBox {
    min-height: 1000px;
    padding: 5vh 0;
  }
}


@media (min-width: 400px){

  #coverageBox{
    min-height: 60vh;
  }
}

@media (min-width: 600px){

  #coverageBox{
    min-height: 30vh;
  }
}

@media (min-width: 900px){

  #coverageBox{
    padding: 10vh 0;
  }
}