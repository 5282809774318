@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

#boxTextHome {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1vh;
}

#boxTextHome > .text{
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
}

#boxTextHome > .text > .blue {
  color: #00ABFF;
  font-weight: 600;
}

#boxTextHome > #h1Home {
  font-size: 210%;
  font-weight: 400;
}

#boxTextHome > #pHome {
  font-size: 93%;
  font-weight: 300;
}

#boxTextHome > #pHome > .blue {
  font-weight: 600;
}

/* > 420 */
@media (min-width: 400px){
  #boxTextHome > #h1Home {
    font-size: 280%;
  }
  
  #boxTextHome > #pHome {
    font-size: 120%;
  }
}
/* > 420 */


/* > 720VW */
  @media (min-width: 720px){
    #boxTextHome > #h1Home {
      font-size: 400%;
    }
    
    #boxTextHome > #pHome {
      font-size: 200%;
    }
  }
/* > 720VW */

/* > 1410VW */
@media (min-width: 1410px){
  #boxTextHome > #h1Home {
    font-size: 75px;
  }
  
  #boxTextHome > #pHome {
    font-size: 25px;
  }
}
/* > 1410VW */


/* > 1410VW  && < 800VW*/
@media screen and (min-width: 1410px) and (max-height: 820px) {
  #boxTextHome {
    gap: 1vh;
  }

  #boxTextHome > #h1Home {
    font-size: 70px;
  }
  
  #boxTextHome > #pHome {
    font-size: 22px;
  }
}
/* > 1410VW  && < 800VW*/

@media screen and (min-width: 1200px) and (max-height: 760px) {
  #boxTextHome > #h1Home {
    font-size: 55px;
  }
  
  #boxTextHome > #pHome {
    font-size: 17px;
  }
}
