#aboutBackground {
  justify-content: center;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: black;
}

#boxTextAbout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1vh;
}

#aboutBox {
  background-image: url('../../assets/images/Group\ 4.png');
  background-size: cover;
  background-position: center;
  width: 100%;
}

#boxTextAbout > .text {
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
}

#boxTextAbout > #h1About {
    font-size: 210%;
    font-weight: 600;
}

#boxTextAbout > #pAbout {
    font-size: 93%;
    font-weight: 500;
}

#pMission, #pValues, #pCompany, #pPurpose {
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
    font-size: 110%;
    font-weight: 300;
    padding: 5px;
}

#pPurpose {
  line-height: 25px;
}

#itemPurpose {
  font-weight: bold;
}

.transparentButton {
    background-color: transparent;
    width: 100%;
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 0;
    font-size: 20px;
    text-align: start;
    position: relative;
}

.arrowButton {
  padding: 5px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 22px;
  text-align: start;
  position: relative;
}

.boxContent {
  background-color: transparent;
  width: 80%;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  text-align: start;
  position: relative;
}

#boxInfos {
    margin-bottom: 30px;
    display: flex;
    gap: 0.5vw;
}

.arrow.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.arrow.animate-close {
  transform: rotate(-0deg);
  transition: transform 0.3s ease-in-out;
}
  
.transparentButton::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin-top: 5px;
}

#aboutBox {
    min-height: 80vh;
    min-width: 320px;
    width: 100vw;
    max-width: 1920px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
}

#aboutBox > #aboutMain {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3vh;
}

@media (min-width: 1921px) {
    #aboutBox {
        min-height: 800px;
    }

    #aboutBox > #aboutMain {
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1vh;
  }
}

/* > 320 */
@media (min-width: 300px) {
    #boxTextAbout > #h1About {
      font-size: 260%;
    }
    
    #boxTextAbout > #pAbout {
      font-size: 110%;
    }

    #boxInfos {

      gap: 1.5vw;
  }
} /* > 420 */
  
/* > 720VW */
@media (min-width: 720px) {
  .transparentButton {
    font-size: 22px;
}

    #boxTextAbout > #h1About {
      font-size: 350%;
    }
    
    #boxTextAbout > #pAbout {
      font-size: 150%;
    }

    #boxInfos {
      gap: 1.5vw;
  }
} /* > 720VW */
  
/* > 1410VW */
@media (min-width: 1410px) {
    #boxTextAbout > #h1About {
      font-size: 65px;
    }
    
    #boxTextAbout > #pAbout {
      font-size: 20px;
    }

    #boxInfos {
      gap: 0.5vw;
  }
} /* > 1410VW */
  
/* > 1410VW && < 800VW */
@media screen and (min-width: 1410px) and (max-height: 820px) {
    #boxTextAbout {
      gap: 1vh;
    }
  
    #boxTextAbout > #h1About {
      font-size: 60px;
    }
    
    #boxTextAbout > #pAbout {
      font-size: 19px;
    }
} /* > 1410VW && < 800VW */

@media screen and (min-width: 1200px) and (max-height: 760px) {
    #boxTextAbout > #h1About {
      font-size: 55px;
    }
    
    #boxTextAbout > #pAbout {
      font-size: 17px;
    }
} /* > 1200px && < 760px */


