#faqBox{
  background-color: #000000;
  min-width: 320px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
}

#faqBox > #faqMain{
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  gap: 5vw;
}

#faqBox > #faqMain > #divider{
  background-color: #00ABFF;
  height: 2.5px;
  width: 80vw;
  border-radius: 5px;
}

/* >= 1000PX */
  @media(min-width: 1300px ){
    #faqBox{
      padding: 0;
      padding-top: 10vh;
    }

    #faqBox > #faqMain{
      flex-direction: row;
      min-height: 90vh;
    }

    #faqBox > #faqMain > #divider{
      background-color: #00ABFF;
      max-height: 600px;
      width: 8px;
      height: 100vh;
      border-radius: 5px;
    }
  }
/* >= 1000PX */

@media screen and (min-width: 1300px) and (max-height: 760px) {

  #faqBox > #faqMain{
    flex-direction: row;
    min-height: 90vh;
  }

  #faqBox > #faqMain > #divider{
    max-height: 650px;
    width: 8px;
    height: 75vh;
    border-radius: 5px;
  }
}

@media (min-width: 1921px) {
  #faqBox > #faqMain {
      min-height: 1000px;
      gap: 0;
  }
  #faqBox{
    padding: 0 ;
  }

  #faqContactMain > #faqform {

    gap: 30px;
}

}


@media (min-width: 960px) {
  #faqBox{
    padding-top: 10vh ;
  }
}

@media (min-width: 1200px) {
  #faqBox {
      padding-top: 5vh;
  }
}