@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

#faqContactMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 6%;
}

#faqContactMain > #faqText {
  width: 85%;
  color: #ffffff;
  font-size: 140%;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 20px 0;
}

#faqContactMain > #faqform {
  width: 85%;
  max-width: 600px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
}

#faqContactMain > #faqform > .input {
  width: 85%;
  height: 35px;
  background-color: #303030;
  border-bottom: 1px solid #ffffff;
  border-radius: 5px;
  padding-left: 10px;
  color: #ffffff;
  font-size: 110%;
  font-family: "Poppins", sans-serif;
}

#faqContactMain > #faqform > .input::placeholder {
  color: #ffffff;
}

#faqContactMain > #faqform > .textarea {
  width: 85%;
  font-size: 120%;
  height: 110px;
  border-radius: 5px;
  padding-top: 5px;
}

#faqContactMain > #faqform > .button {
  width: 35%;
  margin: 20px 0;
  margin-bottom: 40px;
  height: 35px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  background-color: #00ABFF;
  font-size: 130%;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

@media(min-width: 1300px) {
  #faqContactMain > #faqText {
    text-align: left;
    font-size: 52px;
    max-width: 550px;
  }

  #faqContactMain > #faqText > p {
    width: 300px;
  }

  #faqContactMain > #faqform {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 2vh;
    width: 100%;
    max-width: 550px;
  }

  #faqContactMain > #faqform > .button {
    width: 108px;
    height: 30px;
    font-size: 20px;
  }

  #faqContactMain > #faqform > .input {
    width: 85%;
  }

  #faqContactMain > #faqText {
    width: 100%;
  }
}

#faqContactMain > #faqform > .error {
  border-bottom: 1px solid #e63131;
}

.error-message {
  color: #e63131;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



@media(min-width: 720px ){

  #faqContactMain > #faqText {
    font-size: 30px;
  }
}


/* >= 1000PX */
@media(min-width: 1300px ){

  #faqContactMain > #faqText {
    font-size: 40px;
  }
}
/* >= 1000PX */ 

@media screen and (min-width: 1300px) and (max-height: 760px) {

  #faqContactMain > #faqText > p {
    font-size: 35px;
  }

  #faqContactMain > #faqform {
    gap: 1.5vh;
    width: 100%;
    max-width: 550px;
  }

  #faqContactMain > #faqform > .button {
    width: 100px;
    height: 30px;
    font-size: 19px;
  }

  #faqContactMain > #faqform > .input {
    width: 75%;
    height: 40px ;
    font-size: 100%;
  }

  #faqContactMain > #faqText {
    width: 100%;
  }

  #faqContactMain > #faqform > .textarea {
    font-size: 100%;
    height: 90px;
  }
  
}


@media (min-width: 1921px) {
  #faqContactMain{
    padding: 0px;
  }
}