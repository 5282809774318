#homeBox{
  padding-top: 90px;
  min-height: 80vh;
  min-width: 320px;
  width: 100vw;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

#homeBox > #homeMain{
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:6vh;
}


/* > 420 */
@media (min-width: 400px){
  #homeBox{
    min-height: 80vh;
    align-items: center;
  }
}
/* > 420 */

/* > 720 */
  @media (min-width: 720px){
    #homeBox{
      min-height: 80vh;
      min-width: 320px;
      width: 100vw;
      max-width: 1920px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    #homeBox > #homeMain{
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6vh;  
    }
  }


/* > 720 */

/* > 1410VH */
@media screen and (max-width: 750px ) and (max-height: 400px) {
    #homeBox{
      padding-top: 100px;
    }
  }
/* > 1410VH */

/* > 1410VW  && < 800VW*/
@media screen and (min-width: 1300px) and (min-height: 630px) {
  #homeBox{
    padding-top: 100px;
  }
}
/* > 1410VW  && < 800VW*/

/* > 1300VW  && > 630VW*/
@media screen and (min-width: 1300px) and (min-height: 630px) {
  #homeBox{
    padding-top: 100px;
  }
}
/* > 1410VW  && < 800VW*/

/* > 720 */
@media screen and (min-width: 750px) and (min-height: 300px) {
  #homeBox{
    padding-top: 100px;
  }
}
/* > 720 */


@media (min-width: 1921px){
  #homeBox{
    min-height: 1000px;
  }
}