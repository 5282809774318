*{
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  color: inherit;
  text-decoration: none;
  color: inherit;
}

#App{
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  overflow: scroll; /* Permite a rolagem */
  -ms-overflow-style: none;  /* IE e Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;  /* Chrome, Safari e Opera */
}

body{
  overflow-x: hidden;
  background-color: #000000;
}
