#faqTextLeftMain{
  min-width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding-top: 50px;
}

#faqTextLeftMain > p{
  max-width: 80%;
  font-size: 130%;
  font-weight: 500;
}

.blue{
  color: #00ABFF;
}

@media(min-width: 720px ){
  #faqTextLeftMain > p{
    max-width: 90%;
    font-size: 160%;
    font-weight: 600;
  }

}


/* >= 1000PX */
@media(min-width: 1300px ){

  #faqTextLeftMain > p{
    text-align: left;
    max-width: 508px;
    font-size: 50px;
    line-height: 160%;
    padding: 40px 0;

  }

}
/* >= 1000PX */ 


@media screen and (min-width: 1300px) and (max-height: 760px) {
  #faqTextLeftMain > p{
    text-align: left;
    max-width: 508px;
    font-size: 45px;
    line-height: 155%;
  }
}
