@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");


/*BODY*/
  .no-scroll {
    overflow-y: hidden;
  }
/*BODY*/

/*HEADER*/
  .header {
    position: fixed;
    z-index: 2;
    width:  100%;
    max-width: 1920px;
    min-height: 110px;
    height: 100%;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all ease-in 200ms;
    background-image: linear-gradient(to bottom, #000000, 80%, rgba(0, 0, 0, 0));
  }

  /*IS ACTIVE HEADER*/
    .isActiveHeader {
      justify-content: flex-start;
      height: 100vh;
      max-height: none;
      background-color: #000000be;
      transition: all ease-in 200ms;
      overflow-y: scroll
    }
  /*IS ACTIVE HEADER*/
/*HEADER*/



/*BOX LOGO BURGER*/
  /*LOGO*/
  .header > .boxLogoBurger{
    min-height: 100px;
    height: 100%;
    max-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }
  .header > .boxLogoBurger > .headerLogo {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;  
  }

  .header > .boxLogoBurger > .headerLogo > img {
    width: 35%;
    max-width: 70px;
  }
  /*LOGO*/


  /*BURGER*/
  .header > .boxLogoBurger > .burgerBoxOutside {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .header > .boxLogoBurger .burgerBoxOutside > .burgerBoxInside {
    width: 25%;
    max-width: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
  }

  .header > .boxLogoBurger .burgerBoxOutside > .burgerBoxInside > div {
    width: 100%;
    height: 4px;
    background-color: #ffffff;
    border-radius: 6px;
    margin: 4px 0;
    opacity: 1;
    transition: all ease-in 150ms;
  }

    /*IS ACTIVE HEADER*/
      .isActiveHeader > .boxLogoBurger > .burgerBoxOutside > .burgerBoxInside > .burgerLine1 {
        margin: -2px 0;
        transform: rotate(45deg);
      }

      .isActiveHeader > .boxLogoBurger > .burgerBoxOutside > .burgerBoxInside > .burgerLine2 {
        margin: -3px 0;
        opacity: 0;
      }

      .isActiveHeader > .boxLogoBurger > .burgerBoxOutside > .burgerBoxInside > .burgerLine3 {
        margin: -2px 0;
        transform: rotate(-45deg);
      }

      .isActiveHeader > .boxLogoBurger > .burgerBoxOutside > .burgerBoxInside > div {
        transition: all ease-out 150ms;
      }
    /*IS ACTIVE HEADER*/
  /*BURGER*/
/*BOX LOGO BURGER*/

/*NAV*/
  .header > .navMenu {
    display: none;
    min-width: 375px;
    width: 100%;
    min-height: 530px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
  }


  .header > .navMenu ul{
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    font-weight: 350;
    letter-spacing: 1px;
    font-size: 0px;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    list-style: none;
    gap: 0px;
    padding: 0 0;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
  

  .header > .navMenu > .languages > img {  
    height: 0px;
    cursor: pointer;

  }

  /*IS ACTIVE HEADER*/
    .isActiveHeader > .navMenu {
      display: flex;
      height: auto;
      justify-content: center;
      align-items: center;
      padding-top: 3vh;
    }

    .header-dark > .navMenu {
      height: 100%;
      justify-content: center;
    }

    .isActiveHeader > .navMenu ul{
      gap: 5vh;
      font-size: 175%;
      padding: 2vh 0 5vh 0;
    }

    .isActiveHeader > .navMenu > .languages{  
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5vw;
      padding: 2.5vh 0;
    }

    .isActiveHeader > .navMenu > .languages > img {  
      height: 90%;
    }
  /*IS ACTIVE HEADER*/
/*NAV*/

/* SCREEN > 1250*/
  @media screen and (min-width: 1250px) {

    .header{
      flex-direction: row;
    }

  /*BOX LOGO BURGER*/
    /*LOGO*/
      .header > .boxLogoBurger{
        min-height: 100px;
        height: 100%;
        max-height: 100px;
        width: 15vw;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .header > .boxLogoBurger > .headerLogo {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;  
      }

      .header > .boxLogoBurger > .headerLogo > img {
        width: 100%;
        max-width: 70px;
      }
    /*LOGO*/
    /*BURGER*/
      .header > .boxLogoBurger > .burgerBoxOutside{
        display: none;
      } 
    /*BURGER*/
  /*BOX LOGO BURGER*/

  /*NAV*/
    .header > .navMenu {
      display: flex;
      width: 75vw;
      height: 100%;
      min-height: auto;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    .header > .navMenu ul{
      font-family: 'Inter', sans-serif;
      font-weight: 350;
      letter-spacing: 1px;
      font-size: 145%;
      display: flex;
      align-items: center;
      justify-content:center;
      flex-direction: row;
      list-style: none;
      gap: 5%;
      padding: 0 0;
      height: 100%;
      width: 65vw;
    }

    .header > .navMenu ul li{
      color: #ffffff;
      transition: all ease-in 200ms;
    }

    .header > .navMenu ul li:hover{
      color: #00ABFF;
      font-weight: 400;
    }


    .header > .navMenu > .languages{  
      display: flex;
      width: 15vw;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      gap: 5%;
    }

    .header > .navMenu > .languages > img {  
      height: 100%;
      max-width: 35px;
    }

    .header > .navMenu > .languages > img:hover {  
      max-width: 40px;
    }



    }

  @media screen and (min-height: 1150px) {
    .isActiveHeader > .navMenu {
      height: 85%;
      justify-content: center;
    }
  }
/* SCREEN > 1250*/

@media screen and (min-width: 1350px) {
  .header > .navMenu ul{
    font-size: 155%;

  }
}