/* Estilos Base */
#myBody {
  position: relative;
  width: 100vw;
  display: flex;
  padding-top: 10vw;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to top, #000000, 98%, rgba(0, 0, 0, 0));
  min-height: 70vh;
}

.container,.carrousel {
  background-color: black;
  position: relative;
}

.carrousel {
  min-height: 90%;
  min-width: 320px;
  width: 90vw;
  max-width: 1920px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: hidden;
  margin: auto;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding: 30px!important;
}

.custom-bullet {
  width: 8px;
  height: 8px;
  background: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
}

/* Estilo para o bullet ativo */
.custom-bullet-active {
  background: #00ABFF;
}

.slide-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.slide-image {
  max-width: 360px;
  width: 90%;
  height: auto;
}

.slide-item {
  display: block;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

.highlight,.text-blue {
  color: #00ABFF;
  font-weight: bold;
  letter-spacing: 2px;
}

.contentModal {
  line-height: 2em;
  letter-spacing: 2px;
  padding: 5vh;
}

.titleCarrousel,.UnderTitleCarrousel {
  font-family: sans-serif;
  color: white;
  display: flex;
  align-items: center;
  gap: 2vw;
  justify-content: flex-start;
}

.titleModal {
  text-align: center;
  font-size: 60px;
}

.titleModal::after {
  content: '';
  display: block;
  width: 300px;
  height: 5px;
  border-bottom: 5px solid #00ABFF;
  margin: 0 auto 25px;
}

.titleCarrousel {
  margin-bottom: 2%;
}

.UnderTitleCarrousel {
  margin-top: 2%;
  justify-content: flex-end;
}

.listra {
  background-color: white;
  border: 1px solid white;
  width: 50%;
  height: 1px;
  margin-left: 5px;
  align-self: center;
}

/* Media Queries */
@media (min-width: 720px) {
  #myBody {
    min-height: 50vh;
  }

 .titleCarrousel {
    font-size: clamp(1em, 2vw, 2em);
  }

  .UnderTitleCarrousel {
    font-size: clamp(1em, 2vw, 2em);
  }
}

@media (min-width: 1921px) {
  #myBody {
    min-height: 1000px;
    padding: 0;
  }

 .container {
    margin: 0;
  }
}

@media (min-width: 300px) {
  .titleCarrousel {
    font-size: clamp(0.4em, 3vw, 1.5em)
  }

  .UnderTitleCarrousel {
    font-size: clamp(0.4em, 3vw, 1.5em)
  }

  .listra {
    width: 19%;
  }

  .contentModal {
    font-size: 15px;
    line-height: 2em;
    letter-spacing: 2px;
  }

  .titleModal {
    text-align: center;
    font-size: 30px;
  }

  .titleModal::after {
    content: '';
    display: block;
    width: 150px;
    height: 5px;
    border-bottom: 5px solid #00ABFF;
    margin: 0 auto 25px;
  }
}

@media (min-width: 720px) {
 .titleCarrousel {
    font-size: clamp(1em, 2vw, 2em);
  }
  .UnderTitleCarrousel {
    font-size: clamp(1em, 2vw, 2em);
  }

  .listra {
    width: 35%;
  }
}

@media (min-width: 1410px) {
 .titleCarrousel {
    font-size: clamp(1.4em, 1vw, 1.1em);
  }
  .UnderTitleCarrousel {
    font-size: clamp(1.4em, 0vw, 2em);
  }

  .listra {
    width: 59%;
  }

  .contentModal {
    line-height: 2em;
    letter-spacing: 2px;
  }

  .titleModal {
    text-align: center;
    font-size: 60px;
  }

  .titleModal::after {
    content: '';
    display: block;
    width: 300px;
    height: 5px;
    border-bottom: 5px solid #00ABFF;
    margin: 0 auto 25px;
  }
}
