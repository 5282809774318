.closeButton {
  background-color: initial;
  border: none;
  color: #00abff;
  cursor: pointer;
  font-size: 24px;
  left: 100px;
  position: absolute;
  top: 100px;
}

.modalBody {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.95);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  border-radius: 5px;
  height: 100%;
  width: 65%;
  overflow-y: auto;
  z-index: 120;
}

  body.modal-open {
    overflow: hidden;
  }


  @media (min-width: 300px) {
    .modalBody {
      width: 100%;
      padding: 0px;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .buttonModal {
      position: relative;
      bottom: 50px;
    }

    .closeButton {
      background-color: initial;
      border: none;
      color: #00abff;
      cursor: pointer;
      font-size: 24px;
      left: 30px;
      position: absolute;
      top: 60px;
    }
  }
  
  @media (min-width: 720px) {
   .titleCarrousel {
      font-size: clamp(1em, 2vw, 2em);
    }
    .UnderTitleCarrousel {
      font-size: clamp(1em, 2vw, 2em);
    }
  
    .listra {
      width: 35%;
    }

    .modalBody {
      position: fixed;
    }

    .closeButton {
      background-color: initial;
      border: none;
      color: #00abff;
      cursor: pointer;
      font-size: 24px;
      left: 60px;
      position: absolute;
      top: 60px;
    }
  }
  
  @media (min-width: 1410px) {
    .modalBody {
      width: 65%;
      position: fixed;
      top: 50%;
      height: 100%;
      border-radius: 20px;
      height: 100vh;
      position: fixed;
      /* top: 0; */
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .closeButton {
      background-color: initial;
      border: none;
      color: #00abff;
      cursor: pointer;
      font-size: 24px;
      left: 100px;
      position: absolute;
      top: 100px;
    }
  }

