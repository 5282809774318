@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

#contactBox{
  background-color: #000000;
  min-width: 350px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-bottom: 10vh;
}



#contactBox > #contactMain{
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  gap: 1vh;
}

#contactBox > #contactMain > #contactHead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#contactBox > #contactMain > #contactHead  > .contactDivider1{
  width: 100%;
  max-width: 1197px;
  height: 3px;
  border-radius: 10px;
  background-image: linear-gradient(to right, transparent, #00ABFF, transparent);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 200% 5px; /* Ajuste este valor conforme necessário */
}

#contactBox > #contactMain > #contactHead  > .contactDivider2{
  width: 100%;
  max-width: 1197px;
  height: 3px;
  border-radius: 10px;
  background-image: linear-gradient(to right, transparent, #00ABFF, transparent);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 200% 5px; /* Ajuste este valor conforme necessário */
}


#contactBox > #contactMain > #contactHead > #headerText{
  min-width: 290px;
  text-align: center;
}


#contactBox > #contactMain > #contactHead > #headerText > p{
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

#contactBox > #contactMain > #contactHead > #headerText > p > span{
  color: #00ABFF;  
}

#contactBox > #contactMain > #mainContact{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding: 8vh 0;
}

#contactBox > #contactMain > #mainContact >.boxContactOutput {
  max-width: 330px;
  width: 90%;
  min-height: 30px;
  max-height: 100px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 6px 10px;
  gap: 2vw;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.8); /* Adiciona uma sombra interna branca */
}


#contactBox > #contactMain > #mainContact > .boxContactOutput > img{
  max-width: 25px;
  width: 10%;
}

#contactBox > #contactMain > #mainContact > .boxContactOutput > p{
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
}


#contactBox > #contactMain > #contactFooter{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#contactBox > #contactMain > #contactFooter  > .contactDivider{
  width: 95%;
  height: 3px;
  border-radius: 10px;
  background-image: linear-gradient(to right, transparent, #00ABFF, transparent);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 5px; /* Ajuste este valor conforme necessário */
}

@media (min-width: 500px){
  #contactBox > #contactMain > #contactHead > #headerText{
    min-width: 400px;
  }

  #contactBox > #contactMain > #contactHead > #headerText > p{
    font-size: 35px;
  }
}


@media (min-width: 1300px){

  #contactBox{
  min-height: 70vh;
}


  #contactBox > #contactMain > #contactHead > #headerText{
    min-width: 700px;
  }

  #contactBox > #contactMain > #contactHead > #headerText > p{
    font-size: 50px;
  }

  #contactBox > #contactMain > #mainContact > .boxContactOutput{
    min-height: 55px;
    gap: 1vw;
  }

  #contactBox > #contactMain > #mainContact > .boxContactOutput > img{
    width: 7%;
  }
  
  #contactBox > #contactMain > #mainContact > .boxContactOutput > p{
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
  }

  #contactBox > #contactMain > #mainContact >.boxContactOutput {
    max-width: 470px;
    width: 80%;
    min-height: 40px;
    max-height: 100px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 6px 10px;
    gap: 1vw;
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.8); /* Adiciona uma sombra interna branca */
  }

  #contactBox > #contactMain > #mainContact > .boxContactOutput > img{
    max-width: 35px;
    width: 10%;
  }
}


@media (min-width: 1921px) {
  #contactBox{
    min-height: 800px;
    padding: 0;
  }

  #contactBox > #contactMain{
    max-height: 1000px;
  }

  #contactBox > #contactMain > #mainContact {
    padding: 100px;
  }
}