#boxVideoBackgroundHome{
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  z-index: -100; 
}

#boxVideoBackgroundHome > #videoBackgroundHome{
  width: 200vw;
  position: absolute;
  left: -100px;
}

@media (max-width: 1024px){
  #boxVideoBackgroundHome > #videoBackgroundHome{
    width: 300vw;
    position: absolute;
    left: -1000px;
  }  
}

@media (max-width: 720px){
  #boxVideoBackgroundHome > #videoBackgroundHome{
    width: 600vw;
    position: absolute;
    left: -1000px;
  }  
}
